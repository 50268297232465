@font-face {
  font-family: "Iowan Old Style";
  src: url("IowanOldStyle-Roman.woff2") format("woff2"),
    url("IowanOldStyle-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IowanOldSt BT";
  src: url("IowanOldStyleBT-Italic.woff2") format("woff2"),
    url("IowanOldStyleBT-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Iowan Old Style";
  src: url("IowanOldStyle-Bold.woff2") format("woff2"),
    url("IowanOldStyle-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Iowan Old Style";
  src: url("IowanOldStyle-Italic.woff2") format("woff2"),
    url("IowanOldStyle-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Iowan Old Style";
  src: url("IowanOldStyle-Titling.woff2") format("woff2"),
    url("IowanOldStyle-Titling.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Iowan Old Style";
  src: url("IowanOldStyle-Black.woff2") format("woff2"),
    url("IowanOldStyle-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Iowan Old Style";
  src: url("IowanOldStyle-BlackItalic.woff2") format("woff2"),
    url("IowanOldStyle-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd.woff2") format("woff2"),
    url("GillSansStd.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-Condensed.woff2") format("woff2"),
    url("GillSansStd-Condensed.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-Bold.woff2") format("woff2"),
    url("GillSansStd-Bold.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-ExtraBoldDisp.woff2") format("woff2"),
    url("GillSansStd-ExtraBoldDisp.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-ExtraBold.woff2") format("woff2"),
    url("GillSansStd-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-LightItalic.woff2") format("woff2"),
    url("GillSansStd-LightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-LightShadowed.woff2") format("woff2"),
    url("GillSansStd-LightShadowed.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-Italic.woff2") format("woff2"),
    url("GillSansStd-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-UltraBoldCond.woff2") format("woff2"),
    url("GillSansStd-UltraBoldCond.woff") format("woff");
  font-weight: 900;
  font-style: oblique;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-BoldItalic.woff2") format("woff2"),
    url("GillSansStd-BoldItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-UltraBold.woff2") format("woff2"),
    url("GillSansStd-UltraBold.woff") format("woff");
  font-weight: 800;
  font-style: oblique;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-BoldExtraCond.woff2") format("woff2"),
    url("GillSansStd-BoldExtraCond.woff") format("woff");
  font-weight: 600;
  font-style: oblique;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-Shadowed.woff2") format("woff2"),
    url("GillSansStd-Shadowed.woff") format("woff");
  font-weight: 200;
  font-style: oblique;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-BoldCondensed.woff2") format("woff2"),
    url("GillSansStd-BoldCondensed.woff") format("woff");
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url("GillSansStd-Light.woff2") format("woff2"),
    url("GillSansStd-Light.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

.gillsans {
  font-family: "Gill Sans Std" !important;
}

.iowan {
  font-family: "Iowan Old Style" !important;
}

.iowanBT {
  font-family: "IowanOldSt BT" !important;
}
