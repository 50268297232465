.js-help-link {
  display: none !important;
}

#okta-sign-in {
  font-family: "Gill Sans Std" !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border: none;
}

#okta-sign-in .okta-form-title {
  font-family: "Iowan Old Style" !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  line-height: 1.334 !important;
  color: black !important;
}

#okta-sign-in .okta-form-label {
  font-weight: 400 !important;
}

#okta-sign-in .link.help:focus,
#okta-sign-in .focused-input {
  box-shadow: none !important;
}

#okta-sign-in .o-form-input .okta-form-input-field,
#okta-sign-in .o-form-input .okta-form-input-field input {
  color: initial !important;
}

#okta-sign-in .button,
#okta-sign-in #okta-signin-submit {
  text-transform: uppercase !important;
  color: #fff !important;
  font-weight: 400 !important;
  letter-spacing: 0.5px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

#okta-sign-in .auth-org-logo {
  display: none !important;
}

#okta-sign-in .auth-header {
  border: none !important;
}

#okta-sign-in.auth-container.no-beacon .auth-content,
#okta-sign-in.auth-container .auth-content,
#okta-sign-in .enroll-choices,
#okta-sign-in .no-beacon .auth-content {
  padding-top: 0 !important;
  /* padding-top: 20px !important; */
}

#okta-sign-in.auth-container .okta-sign-in-header .beacon-container {
  position: relative;
  width: 4rem;
  height: 4rem;
}

#okta-sign-in.auth-container .okta-sign-in-header .beacon-container:empty {
  -webkit-transform: unset !important;
  transform: unset !important;
  background: #fafafa !important;
  border-radius: 50%;
  content: url("./icon-navy.png");
  padding: 5px;
  border: 1px solid #d8d8d8;
}

#okta-sign-in .beacon-container {
  bottom: 0 !important;
}

#okta-sign-in .okta-sign-in-header .beacon-container.beacon-small {
  bottom: 0 !important;
}

#okta-sign-in
  .okta-sign-in-header
  .beacon-container.beacon-small.beacon-loading,
#okta-sign-in .beacon-container {
  transform: scale(0.9) !important;
  background: #fafafa !important;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid #d8d8d8;
}

#okta-sign-in
  .okta-sign-in-header
  .beacon-container.beacon-small.beacon-loading:after {
  background: #fafafa !important;
}

#okta-sign-in.no-beacon .auth-header,
#okta-sign-in .okta-sign-in-header.auth-header {
  padding-bottom: 10px !important;
}

#okta-sign-in .enroll-factor-label {
  font-weight: 300 !important;
}
